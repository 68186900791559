body {
  width: 100vw; /* 100% of the viewport width */
  min-width: 100%; /* Ensure a minimum width of 100% */
  overflow-x: hidden; /* Hide horizontal overflow */
  overflow-y: auto; /* Allow vertical overflow if needed */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}



.watchvideo:hover {
  color: #D65B03;
  
}

.watchvideo {
}

.glow-on-hover {
  width: 170px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 70px !important;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #ff6b00, #cecece, #d65b03);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 1; /* Set initial opacity to 1 */
  transition: opacity .3s ease-in-out;
  border-radius: 70px !important;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 70px;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}


.skills {
  width: 90%;
  padding: 0 20px;
}

.skill-name {
  font-size: 18px;
  font-weight: 700;
  color: black;
  text-transform: uppercase;
  margin: 20px 0;
}

.skill-bar {
  height: 1px;
  background: #4b4a4a;
  border-radius: 3px;
}

.skill-percentage {
  height: 1px;
  background: black;
  border-radius: 3px;
  position: relative;
  animation: fillBars 2.5s 1;
}

.skill-percentage::before{
  content: attr(per);
  position: absolute;
  padding: 4px 6px;
  background: #Fff5fd;
  border-radius: 4px;
  font-size: 12px;
  top: -35px;
  right: 0;
  transform: transition(50%)  
}

/* .skill-percentage::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  background: #005A8D;
  top: -20px;
  right: 0;
  transform: translateX(50%) rotate(45deg);
  border-radius: 2px;
} */

@keyframes fillBars {
  from{
    width: 0;
  }
  to{
   width: 100%; 
  }
}
/* .one,
.two,
.three {
  height: 100vh;
  width: 100%;
}
.one {
  background-color: rgb(66, 60, 57);
}
.two {
  background-color: cornflowerblue;
} */
  .three {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .three .container {
    width: 90%;
    margin: 0 auto;
    display: grid;
    padding: 0 20px;
    align-items: center;
    grid-template-rows: repeat(3, 80px);
  }
  .three h3 {
    font-size: 16px;
  }
  .three .progress__lang {
    border: 1px solid rgb(126, 114, 114);
    height: 3px;
    margin-top: 5px;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .three .progress__lang span {
    position: absolute;
    top: 0;
    left: 0;
    background-color: crimson;
    height: 100%;
    width: 0;
    display: block;
    transition: width 0.4s ease-in-out;
  }






.blur-button {
  @apply text-white text-[20px] font-gilroy px-7 py-2 bg-transparent;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  margin-right: 14px;
  border-radius: 34px;
}


.textile-container1 {
  position: relative;
  overflow: hidden;
}

.textile-container1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 670px;
  height: 370px;
  background: url('./Component Library/Gots\ Back.png') center/contain no-repeat;
  opacity: 0;
  transition: opacity 0.3s ease-in-out; /* Add transition for opacity */
}

.textile-container1:hover::before {
  opacity: 1; /* Adjust the opacity as needed */
}

.textile-container1 img {
  width:  670px;
  height: 370px;
  transition: opacity 0.3s ease-in-out; /* Optional: Add transition to the original image */
  z-index: 2; /* Ensure the image is on top of the pseudo-element */
}

.textile-container1:hover img {
  opacity: 0; /* Hide the first image when the container is hovered */
}





.textile-container2 {
  position: relative;
  overflow: hidden;
}

.textile-container2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 670px;
  height: 370px;
  background: url('./Component Library/Grs\ Back.png') center/contain no-repeat;
  opacity: 0;
  transition: opacity 0.3s ease-in-out; /* Add transition for opacity */
}

.textile-container2:hover::before {
  opacity: 1; /* Adjust the opacity as needed */
}

.textile-container2 img {
  width:  670px;
  height: 370px;
  transition: opacity 0.3s ease-in-out; /* Optional: Add transition to the original image */
  z-index: 2; /* Ensure the image is on top of the pseudo-element */
}

.textile-container2:hover img {
  opacity: 0; /* Hide the first image when the container is hovered */
}






.gradient-text1 {
  background: linear-gradient(to right, #E3771C, #FFD600);
  -webkit-background-clip: text;
  color: transparent;
}


.gradient-tiruppur {
  background: linear-gradient(to right, #D65B03 50%, #FFAF74 50%);
    -webkit-background-clip: text;
  color: transparent;
}








.whatmakes-container5 {
  position: relative;
  overflow: hidden;
}

.whatmakes-container5::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 971px;
  height: 907px;
  background: url('./Component Library/WhatMakes2.png') center/contain no-repeat;
  opacity: 0;
  transition: opacity 0.3s ease; /* Add transition for opacity */
}

.whatmakes-container5:hover::before {
  opacity: 1; /* Adjust the opacity as needed */
}

.whatmakes-container5 img {
  width: 971px;
  height: 907px;
  transition: opacity 0.3s ease; /* Optional: Add transition to the original image */
  z-index: 2; /* Ensure the image is on top of the pseudo-element */
}
















/* proudts page styles */

.image-container1 {
  position: relative;
  overflow: hidden;
}

.image-container1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 582px;
  height: 666px;
  background: url('./Component Library/t-shirts4.png') center/contain;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container1:hover::before {
  opacity: 2; /* Adjust the opacity as needed */
  width: 100%;
  height :85%;
  background-repeat: no-repeat;
}

.image-container1 img {
  width: 582px;
  height: 666px;
  transition: width 2s ease; /* Optional: Add transition to the original image */
}

.image-container2 {
  position: relative;
  overflow: hidden;
}

.image-container2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 582px;
  height: 666px;
  background: url('./Component Library/tshirts-5.png') center/contain;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container2:hover::before {
  opacity: 2; /* Adjust the opacity as needed */
  width: 100%;
  height :85%;
  background-repeat: no-repeat;
}

.image-container2 img {
  width: 582px;
  height: 666px;
  transition: width 2s ease; /* Optional: Add transition to the original image */
}



.image-container3 {
  position: relative;
  overflow: hidden;
}

.image-container3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 582px;
  height: 666px;
  background: url('./Component Library/tshirts-6.png') center/contain;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container3:hover::before {
  opacity: 2; /* Adjust the opacity as needed */
  width: 100%;
  height :85%;
  background-repeat: no-repeat;
}

.image-container3 img {
  width: 582px;
  height: 666px;
  transition: width 2s ease; /* Optional: Add transition to the original image */
}









/* proudts page styles */

/* quality section style */
.quality-container1 {
  position: relative;
  overflow: hidden;
}

.quality-container1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 591px;
  height: 616px;
  background: url('./Component Library/quality4.png') center/contain no-repeat;
  opacity: 0;
  transition: opacity 0.3s ease; /* Add transition for opacity */
}

.quality-container1:hover::before {
  opacity: 1; /* Adjust the opacity as needed */
}

.quality-container1 img {
  width: 591px;
  height: 616px;
  transition: opacity 0.3s ease; /* Optional: Add transition to the original image */
  z-index: 2; /* Ensure the image is on top of the pseudo-element */
}



.quality-container2 {
  position: relative;
  overflow: hidden;
}

.quality-container2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 591px;
  height: 616px;
  background: url('./Component Library/quality5.png') center/contain no-repeat;
  opacity: 0;
  transition: opacity 0.3s ease; /* Add transition for opacity */
}

.quality-container2:hover::before {
  opacity: 1; /* Adjust the opacity as needed */
}

.quality-container2 img {
  width: 591px;
  height: 616px;
  transition: opacity 0.3s ease; /* Optional: Add transition to the original image */
  z-index: 2; /* Ensure the image is on top of the pseudo-element */
}

.quality-container3 {
  position: relative;
  overflow: hidden;
}

.quality-container3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 591px;
  height: 922px;
  background: url('./Component Library/quality6.png') center/contain no-repeat;
  opacity: 0;
  transition: opacity 0.3s ease; /* Add transition for opacity */
}

.quality-container3:hover::before {
  opacity: 1; /* Adjust the opacity as needed */
}

.quality-container3 img {
  width: 591px;
  height: 912px;
  transition: opacity 0.3s ease; /* Optional: Add transition to the original image */
  z-index: 2; /* Ensure the image is on top of the pseudo-element */
}


/* quality section style*/



/* sweater section styles*/

.sweater1 {
  position: relative;
  overflow: hidden;
}

.sweater1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./Component Library/frame1.png') center/contain;
  background-repeat: no-repeat;
  opacity: 1;
  transition: opacity 0.3s ease, width 0.3s ease; /* Added transition for width */
}



.sweater1 img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  transition: width 2s ease; /* Optional: Add transition to the original image */
}


.sweater2 {
  position: relative;
  overflow: hidden;
}

.sweater2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./Component Library/frame2.png') center/contain;
  background-repeat: no-repeat;
  opacity: 1;
  transition: opacity 0.3s ease, width 0.3s ease; /* Added transition for width */
}


.sweater2 img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  transition: width 2s ease; /* Optional: Add transition to the original image */
}


.sweater3 {
  position: relative;
  overflow: hidden;
}

.sweater3::before { 
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./Component Library/frame3.png') center/contain;
  background-repeat: no-repeat;
  opacity: 1;
  transition: opacity 0.3s ease, width 0.3s ease; /* Added transition for width */
}



.sweater3 img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  transition: width 2s ease; /* Optional: Add transition to the original image */
}



.sweater4 {
  position: relative;
  overflow: hidden;
}

.sweater4::before { 
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./Component Library/sweater1.png') center/contain;
  background-repeat: no-repeat;
  opacity: 1;
  transition: opacity 0.3s ease, width 0.3s ease; /* Added transition for width */
}



.sweater4 img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  transition: width 2s ease; /* Optional: Add transition to the original image */
}



.sweater5 {
  position: relative;
  overflow: hidden;
}

.sweater5::before { 
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./Component Library/frame5.png') center/contain;
  background-repeat: no-repeat;
  opacity: 1;
  transition: opacity 0.3s ease, width 0.3s ease; /* Added transition for width */
}



.sweater5 img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  transition: width 2s ease; /* Optional: Add transition to the original image */
}


.sweater6 {
  position: relative;
  overflow: hidden;
}

.sweater6::before { 
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./Component Library/framesix.png') center/contain;
  background-repeat: no-repeat;
  opacity: 1;
  transition: opacity 0.3s ease, width 0.3s ease; /* Added transition for width */
}


.sweater6 img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  transition: width 2s ease; /* Optional: Add transition to the original image */
}

/* sweater section styles*/





/* Example Tailwind CSS styles */
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  position: relative;
  overflow: hidden;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container .skill-box {
  width: 100%;
  margin: 25px 0;
}

.skill-box .title {
  display: block;
  font-size: 24px;
  font-weight: 400;
  color: #333;
}

.skill-box .skill-bar {
  height: 2px;
  width: 400px;
  border-radius: 6px;
  margin-top: 26px;
  background: rgba(0, 0, 0, 0.1);
}

.skill-box .skill-per {
  position: relative;
  display: block;
  height: 100%;
  width: 95%;
  border-radius: 6px;
  background: black;    
  animation: progress 0.9s ease-in-out forwards;
}


.skill-per.gender {
  width: 94%;
  animation-delay: 0.1s;
}



.skill-per.water {
  width: 50%;
  animation-delay: 0.1s;
}

.skill-per.solar {
  width: 90%;
  animation-delay: 0.1s;
}

.skill-per.chemical {
  width: 30%;
  animation-delay: 0.1s;
}

.skill-per.healthy {
  width: 81%;
  animation-delay: 0.3s;
}

.skill-per.client {
  width: 94%;
  animation-delay: 0.3s;
}

.skill-per .tooltip {
  position: absolute;
  right: -14px;
  top: -28px;
  font-size: 15px;
  font-weight: 500;
  color: #000000;
  padding: 2px 6px;
  border-radius: 3px;
  background: transparent;
  z-index: 1;
}

@keyframes progress {
  0% {
    width: 0;
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}







@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');
@import 'swiper/swiper-bundle.min.css';

@font-face {
    font-family: 'Gilroy';
    src: url('../src/fonts/Gilroy-Bold.ttf') /* Adjust the file path accordingly */
   
  }

  @font-face {
    font-family: 'Gilroy-Reqular';
    src: url('../src/fonts/Gilroy-Regular.ttf') /* Adjust the file path accordingly */
   
  }

  @font-face {
    font-family: 'Gilroy-Ligt';
    src: url('../src/fonts/Gilroy-Light.ttf') /* Adjust the file path accordingly */
   
  }

  @font-face {
    font-family: 'Aeonik';
    src: url('../src/fonts/AeonikFont/AeonikTRIAL-Regular.otf') /* Adjust the file path accordingly */
   
  }

  
  @font-face {
    font-family: 'Causten';
    src: url('../src/fonts/CasternFont/Causten-Regular.otf') /* Adjust the file path accordingly */
   
  }

  

/* Example Tailwind CSS styles */
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  position: relative;
  overflow: hidden;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}



/* Change label text color when radio button is checked */
input[type='radio']:checked + label {
    color: rgb(0, 0, 0);
}






.border-l-3 {
  border-left-width: 2px; /* Reduce the height of the left border */
}


.gradient-border {
  border-left-width: 4px; /* Adjust the width of the left border */
  height: 55px;
  border-image: linear-gradient(to bottom, #00BEFB, #1CC3B0) 1; /* Add gradient to the left border */
}
/* Hide other borders */
.border {
  border-top-width: 0 !important;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
}


.gradient-understand  {
  background: linear-gradient(90deg, #181818 -1.9%, #000000  100%);
 
   }



   .gradient-understa  {
  background: linear-gradient(180deg, #181818 -1.9%, #000000  100%);
 
   }

   .gradient-bdr{
    background: linear-gradient(90deg, #ffffff -1.9%, #000000  100%);
   
     }

     .gradient-bdrs{
      background: linear-gradient(180deg, #ffffff -1.9%, #000000  100%);
     
       }

.gradient-recycle {
  background: linear-gradient(91.33deg, #00C614 -18.33%, #8DCB93 180.05%);
  -webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
   }





.gradient-texts {
  background: linear-gradient(113.57deg, #1CC3B0 -11.09%, #00BEFB 171.56%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
   }




.gradient-text {
 background: linear-gradient(#0D712C 54%,#9ACA3C);
 background-clip: text;
 -webkit-background-clip: text;
 color: transparent;
  }

  .gradient-text1 {
    background: linear-gradient(#E3771C 94%,#FFD600); /* Adjust the colors as needed */
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }

  .gradient-thirupur {
    background: linear-gradient(#D65B03 44%,#FFAF74); /* Adjust the colors as needed */
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }


  .environment-content {
    margin-left: 550px; /* Adjust the margin-left for the Environment tab content */
    width:89%;
  }
  
  /* Add right-side border to all tabs except the last one */
  .border-r-2:not(:last-child) {
    border-right: 2px solid black; /* Adjust the border color and size as needed */
  }

/* Add these styles to your CSS */
.hover-info:hover .percentage-info {
    display: block;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }


@keyframes progress {
    0% {
        width: 0;
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

/* .skill-per .tooltip{
    position: absolute;
    right: -14px;
    top: -28px;
    font-size: 9px;
    font-weight: 500;
    color: #fff;
    padding: 2px 6px;
    border-radius: 3px;
    background: crimson;
    z-index: 1;
}

.tooltip::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -2px;
    height: 10px;
    width: 10px;
    z-index: -1;
    background-color: crimson;
    transform: translateX(-50%) rotate(45deg);
} */

.css-1aquho2-MuiTabs-indicator {
    position: absolute;
    height: 2px;
    bottom: 0;
    width: 100%;
    -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #ff6b00;
}


@media only screen and (max-width:1355px) {
.gradient-border {
  border-left-width: 1.5px; /* Adjust the width of the left border */
  height: 34px; 
  /* padding: -44px; */
  border-image: linear-gradient(to bottom, #00BEFB, #1CC3B0) 1; /* Add gradient to the left border */
}

}






  @media only screen and (max-width: 425px) {
    .glow-on-hover {
      width: 118px;
      height:32px;
    }

    .skill-box .skill-bar {
      height: 1px;
      width: 231px;
      border-radius: 6px;
      margin-top: 9px;
      background: rgba(0, 0, 0, 0.1);
  }


.skill-box .title {
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-top: -12px;
  color: #333;
} 


.skill-box .skill-per {
  position: relative;
  display: block;
  height: 40%;
  width: 95%;
  border-radius: 6px;
  background:black;    
  animation: progress 0.9s ease-in-out forwards;
}


.skill-per.gender {
  width: 94%;
  animation-delay: 0.1s;
}



.skill-per.water {
  width: 70%;
  animation-delay: 0.1s;
}

.skill-per.solar {
  width: 90%;
  animation-delay: 0.1s;
}

.skill-per.chemical {
  width: 75%;
  animation-delay: 0.1s;
}

.skill-per.healthy {
  width: 81%;
  animation-delay: 0.3s;
}

.skill-per.client {
  width: 94%;
  animation-delay: 0.3s;
}


.gradient-border {
  border-left-width: 1.5px; /* Adjust the width of the left border */
  height: 24px;
  border-image: linear-gradient(to bottom, #00BEFB, #1CC3B0) 1; /* Add gradient to the left border */
}




}